<template>
    <div class="field-order-info">
        <el-row :gutter="20">
            <!-- 图片列表列 -->
            <el-col :lg="11" :md="12" ref="imgList">
                <!-- 小图列表-->
                <img-list
                    :md-img="mdImg"
                    :img-list="imgs"
                    @change-md="handleMdChange">
                </img-list>
            </el-col>
            <!-- 选项表单部分 -->
            <el-col :lg="13" :md="12">
                <div class="order-form-box">
                    <div class="order-form-title">{{info.name}}</div>
                    <el-form
                        label-width="180px"
                        label-position="left"
                        :model="form"
                        :rules="rules"
                        ref="fieldForm"
                        hide-required-asterisk>
                        <!-- 场地价格 -->
                        <el-form-item label="价格/Price">
                            <div class="order-form-price">
                                <span>¥</span>
                                <span>{{price || info.price}}</span>
                                <span>/小时</span>
                            </div>
                        </el-form-item>
                        <!-- 地址 -->
                        <el-form-item
                            label="地址/Address">
                            <div class="order-form-address">
                                <i class="el-icon-location-outline"></i>
                                <span>{{info.contact.address}}</span>
                            </div>
                        </el-form-item>
                        <!-- 套餐 -->
                        <el-form-item
                            label="套餐/Packages">
                            <ul class="order-form-packages">
                                <li :class="{
                                        'select-packages': form.select_pack == item.name
                                    }"
                                    v-for="(item, index) in info.series"
                                    :key="index">
                                    <a href="javascript:;"
                                        @click="handlePackages(item)">
                                        {{item.name}}
                                    </a>
                                </li>
                            </ul>
                        </el-form-item>
                        <!-- 租赁日期 -->
                        <el-form-item
                            label="开始时间 / Start Time"
                            prop="startDate">
                            <el-date-picker
                                v-model="form.startDate"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                @change="startChange"
                                :picker-options="{
                                    selectableRange: begin_time_picker,
                                    disabledDate: startDisabledDate
                                }">
                            </el-date-picker>
                        </el-form-item>
                        <!-- 租赁时间 -->
                        <el-form-item
                            label="结束时间 / End Time"
                            prop="endDate">
                            <el-date-picker
                                v-model="form.endDate"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                @change="endChange"
                                :picker-options="{
                                    selectableRange: end_time_picker,
                                    disabledDate: endDisabledDate
                                }">
                            </el-date-picker>
                        </el-form-item>
                        <!-- 拍摄人数 -->
                        <el-form-item
                            label="拍摄人数 / Number"
                            prop="num">
                            <el-input-number
                                v-model="form.num"
                                :min="1">
                            </el-input-number>
                        </el-form-item>
                        <!-- 按钮 -->
                        <el-form-item label="">
                            <el-button
                                type="warning"
                                class="add-order"
                                v-if="!info.price == 0"
                                @click="handleAddOrder('fieldForm')">立即下单</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
        </el-row>
        <detail-tabs
            :detail-content="info.introduce">
        </detail-tabs>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        const validateDate = (rule, value, callback) => {
            if (value) {
                let tomorrow = new Date(new Date().getTime() + 24*60*60*1000);
                let select_date = new Date(value);
                if (select_date < tomorrow) {
                    callback(new Error('时间不可小于当前时间的24小时之后'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        return {
            price: '',
            form: {
                select_pack: "",
                startDate: "",
                endDate: "",
                num: "",
            },
            rules: {
                startDate: [
                    { required: true, validator: validateDate, trigger: 'change' }
                ],
                endDate: [
                    {required: true, message: '请选择结束时间', trigger: 'blur'}
                ],
                num: [
                    {required: true, message: '请选择拍摄人数', trigger: 'blur'}
                ],
            },
            startDisabledDate: (time) => {
                // let nowData = new Date()
                // nowData = new Date(nowData.setDate(nowData.getDate() - 1))
                // return time < nowData
                return time.getTime() < Date.now();
            },
            endDisabledDate: (time) => {
                return time.getTime() < Date.now();
            }
        }
    },
    computed: mapState({
        info: state => state.goodsDetail.info,
        is_login: state => state.user.is_login,
        mdImg: state => state.goodsDetail.mdImg,
        imgs: state => state.goodsDetail.imgs,
        begin_time_picker() {
            let today = new Date(new Date().setHours(0, 0, 0, 0));
            let tomorrow = new Date(today.setDate(today.getDate()+2));
            let select_date = this.form.startDate == "" || this.form.startDate == null? "": new Date(this.form.startDate);
            let param_time = "", param_arr = []; // 返回参数
            param_arr.push(this.nowTime());
            param_arr.push("00:00:00-23:59:59");
            // if(select_date == "" || select_date<tomorrow){
            //     param_time = this.nowTime();
            //     console.log("限制时间："+param_time)
            // }else{
            //     param_time = "00:00:00-23:59:59"
            //     console.log("非限制时间："+param_time)
            // }
            // console.log(param_time)
            return param_arr;
        },
        end_time_picker() {
            let param_time = ""; // 返回参数
            if(this.form.startDate == "" || this.form.startDate == null){
                param_time = this.nowTime();
            }else{
                let begin_time = this.form.startDate.split(" "),
                h_m = begin_time[1];
                param_time = h_m + ":00-23:59:59";
            }
            return param_time;
        }
    }),
    components:{
        imgList: () => import('@/components/detailImgList'),
        detailTabs: ()=> import('@/components/detailTabs'),
        fieldOrderPack: ()=> import('@/components/fieldOrderPack'),
    },
    methods: {
        ...mapMutations([
            "changeMdImg", // 更改中图片api
        ]),
        ...mapActions([
            "handleFieldAddOrder"
        ]),
        handleMdChange(md,index) {
            this.changeMdImg({
                md,
                index
            });
        },
        nowTime(){
            let now_date = new Date(),
            now_hours = now_date.getHours(),
            now_minutes = now_date.getMinutes(),
            h = now_hours < 10? ('0' + now_hours): now_hours,
            m = now_minutes < 10? ('0' + now_minutes): now_minutes,
            now_time =  h + ":" + m + ":" + "00" + "-" + "23:59:59";
            return now_time;
        },
        endChange(){
            var time=this.DateDiff2(this.form.startDate,this.form.endDate);
            alert(time);
        },
        DateDiff2(date1 , date2) {    //date1和date2是2002-12-18格式
			if(date1&&date2){
				let date1Str = date1.split("-");//将日期字符串分隔为数组,数组元素分别为年.月.日
				//根据年 . 月 . 日的值创建Date对象
				let date1Obj = new Date(date1Str[0],(date1Str[1]-1),date1Str[2]);
				let date2Str = date2.split("-");
				let date2Obj = new Date(date2Str[0],(date2Str[1]-1),date2Str[2]);
				let t1 = date1Obj.getTime();
				let t2 = date2Obj.getTime();
				let dateTime = 1000*60*60*24; //每一天的毫秒数
                let minusDays = Math.floor(((t2-t1)/dateTime));//计算出两个日期的天数差
                let hours=minusDays*24;
                return Math.abs(hours);
                }else{
                    return"213";
                }
        },
        handlePackages(item){
            this.form.select_pack = item.name;
            this.price = item.price;
        },
        startChange(val){
            let start = new Date(val).getTime(),
            end = new Date(this.form.endDate).getTime();
            if(start > end || val == ""){
                this.form.endDate = "";
            }
            if(start >= end){
              this.form.endDate = "";
              this.$message({
                showClose: true,
                message: "结束时间不得小于或等于开始时间！",
                type: "error"
              })
            }
            if((end-start)/(1000*60)<60){
              this.form.endDate="";
              this.$message({
                showClose: true,
                message: "租赁时间不得小于一小时",
                type: "error"
              })
            }
        },
        endChange(val){
            let start = new Date(this.form.startDate).getTime(),
            end = new Date(val).getTime();
            if(start >= end){
                this.form.endDate = "";
                this.$message({
                    showClose: true,
                    message: "结束时间不得小于或等于开始时间！",
                    type: "error"
                })
            }
            if((end-start)/(1000*60)<60){
             this.form.endDate="";
             this.$message({
                 showClose: true,
                    message: "租赁时间不得小于一小时",
                    type: "error"
             })
            }
        },
        handleAddOrder(form){ // 立即下单
            if(!this.is_login){ // 未登录提示
                this.$message({
                    showClose: true,
                    message: "您还未登录！",
                    type: "warning"
                })
                return;
            }
            if(this.form.startDate==null||this.form.startDate==""){
                    this.$message({
                    showClose: true,
                    message: "请选择开始时间",
                    type: "error"
                })
                return;
            }
            this.$refs[form].validate((valid) =>{
                if (valid) {
                    this.handleFieldAddOrder({
                        price: this.info.price,
                        field_id: this.info.id,
                        series: this.form.select_pack,
                        lease_begin: this.form.startDate,
                        lease_end: this.form.endDate,
                        photography_number: this.form.num
                    }).then((res) => {
                        if(res.type){
                            this.$router.push({
                                name: "fieldPay",
                                query: {
                                    id: res.msg
                                }
                            })
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.msg,
                                type: "error"
                            })
                        }
                    })
                } else {
                    return false;
                }
            })
        }
    },
}
</script>



<style scoped>
/* 预约拍照详情盒子 */
.field-order-info{
    margin-bottom: 50px;

}

/* 图片列表区域盒子 */
.order-form-box{
    padding: 20px;
}
/* 表单标题 */
.order-form-title{
    margin-bottom: 50px;
    font-size: 23px;
    font-weight: bold;
}

/* 表单区域 */
.order-form-box>>>.el-form-item__label{
    font-weight: 100;
    color: #909399
}
/* 场地名称 */
.order_form_name{
    font-size: 25px;
    font-weight: bold;
    color: #303133;
}
/* 价格 */
.order-form-price{
    color: #E6A23C;
}
.order-form-price>span:nth-child(2){
    margin-left: 10px;
    font-size: 25px;
    font-weight: bold;
}
/* 地址 */
.order-form-address > i{
    margin-right: 4px;
    font-size: 16px;
    color: #8d9afc;
}
/* 套餐 */
.order-form-packages{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding-bottom: 5px;
}
.order-form-packages > li{
    float: left;
    margin-right: 22px;
    margin-bottom: 10px;
    padding: 4px 10px;
    line-height: 1;

    border-radius: 30px;
}
.order-form-packages > li > a{
    display: block;
    color: #303133;
}
.order-form-packages > .select-packages{
    background-color: #E6A23C;
}
.order-form-packages > .select-packages > a{
    color: #fff;
}
/* 立即下单按钮 */
.add-order{
    width: 200px;
    height: 50px;
}
</style>
