var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field-order-info"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{ref:"imgList",attrs:{"lg":11,"md":12}},[_c('img-list',{attrs:{"md-img":_vm.mdImg,"img-list":_vm.imgs},on:{"change-md":_vm.handleMdChange}})],1),_c('el-col',{attrs:{"lg":13,"md":12}},[_c('div',{staticClass:"order-form-box"},[_c('div',{staticClass:"order-form-title"},[_vm._v(_vm._s(_vm.info.name))]),_c('el-form',{ref:"fieldForm",attrs:{"label-width":"180px","label-position":"left","model":_vm.form,"rules":_vm.rules,"hide-required-asterisk":""}},[_c('el-form-item',{attrs:{"label":"价格/Price"}},[_c('div',{staticClass:"order-form-price"},[_c('span',[_vm._v("¥")]),_c('span',[_vm._v(_vm._s(_vm.price || _vm.info.price))]),_c('span',[_vm._v("/小时")])])]),_c('el-form-item',{attrs:{"label":"地址/Address"}},[_c('div',{staticClass:"order-form-address"},[_c('i',{staticClass:"el-icon-location-outline"}),_c('span',[_vm._v(_vm._s(_vm.info.contact.address))])])]),_c('el-form-item',{attrs:{"label":"套餐/Packages"}},[_c('ul',{staticClass:"order-form-packages"},_vm._l((_vm.info.series),function(item,index){return _c('li',{key:index,class:{
                                    'select-packages': _vm.form.select_pack == item.name
                                }},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.handlePackages(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)]),_c('el-form-item',{attrs:{"label":"开始时间 / Start Time","prop":"startDate"}},[_c('el-date-picker',{attrs:{"type":"datetime","format":"yyyy-MM-dd HH:mm","value-format":"yyyy-MM-dd HH:mm","picker-options":{
                                selectableRange: _vm.begin_time_picker,
                                disabledDate: _vm.startDisabledDate
                            }},on:{"change":_vm.startChange},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})],1),_c('el-form-item',{attrs:{"label":"结束时间 / End Time","prop":"endDate"}},[_c('el-date-picker',{attrs:{"type":"datetime","format":"yyyy-MM-dd HH:mm","value-format":"yyyy-MM-dd HH:mm","picker-options":{
                                selectableRange: _vm.end_time_picker,
                                disabledDate: _vm.endDisabledDate
                            }},on:{"change":_vm.endChange},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1),_c('el-form-item',{attrs:{"label":"拍摄人数 / Number","prop":"num"}},[_c('el-input-number',{attrs:{"min":1},model:{value:(_vm.form.num),callback:function ($$v) {_vm.$set(_vm.form, "num", $$v)},expression:"form.num"}})],1),_c('el-form-item',{attrs:{"label":""}},[(!_vm.info.price == 0)?_c('el-button',{staticClass:"add-order",attrs:{"type":"warning"},on:{"click":function($event){return _vm.handleAddOrder('fieldForm')}}},[_vm._v("立即下单")]):_vm._e()],1)],1)],1)])],1),_c('detail-tabs',{attrs:{"detail-content":_vm.info.introduce}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }